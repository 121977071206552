export const LOGIN_REQ="LOGIN_REQ";
export const LOGIN_SUCCESS="LOGIN_SUCCESS";
export const LOGIN_FAIL="LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOAD_PROF = "LOAD_PROF";

export const ADD_SIGN_DATA_REQ="ADD_SIGN_DATA_REQ";
export const ADD_SIGN_DATA_SUCCESS="ADD_SIGN_DATA_SUCCESS";
export const ADD_SIGN_DATA_FAIL="ADD_SIGN_DATA_FAIL";

export const GET_SIGN_DATA_REQ="GET_SIGN_DATA_REQ";
export const GET_SIGN_DATA_SUCCESS="GET_SIGN_DATA_SUCCESS";
export const GET_SIGN_DATA_FAIL="GET_SIGN_DATA_FAIL";

export const GET_TOP_USERS_REQ="GET_TOP_USERS_REQ";
export const GET_TOP_USERS_SUCCESS="GET_TOP_USERS_SUCCESS";
export const GET_TOP_USERS_FAIL="GET_TOP_USERS_FAIL";